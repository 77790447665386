import * as React from "react"
import Seo from "../components/seo"
import { Button, Result } from "antd"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <div>
    <Seo title="404: Not found" />
    <Result
      status="404"
      title="404"
      subTitle="Oups, cette page n'exite pas !"
      extra={<Button type="primary"><Link to={"/"}>Accueil</Link></Button>}
    />,
  </div>
)

export default NotFoundPage
